import { FlowEditorSDK } from '@wix/yoshi-flow-editor';

export const createInstallProgressHandler = ({
  editorSDK,
  compId,
  t,
}: {
  editorSDK: FlowEditorSDK;
  compId: string;
  t: (key: string) => string;
}) => {
  let state: 'initial' | 'pending' | 'done' = 'initial';
  return {
    start: () => {
      if (state === 'initial') {
        try {
          editorSDK.editor.openProgressBar('token', {
            title: t('progress-bar.title'),
            totalSteps: 1,
          });
        } catch (e) {}
        state = 'pending';
      }
    },
    finish: async () => {
      if (state === 'pending') {
        state = 'done';
        try {
          await editorSDK.editor.updateProgressBar('token', {
            currentStep: 1,
          });
          setTimeout(() => {
            editorSDK.editor.closeProgressBar('token', {});
          }, 1000);
        } catch (e) {}
      }
    },
    fail: async () => {
      if (state === 'pending') {
        state = 'done';
        try {
          await editorSDK.components.remove('token', {
            componentRef: { id: compId, type: 'DESKTOP' },
          });
          await editorSDK.editor.closeProgressBar('token', {});
          await editorSDK.editor.openErrorPanel('token', {
            shouldShowIllustration: false,
            headerText: t('error-panel.title'),
            topDescriptionText: t('error-panel.description'),
            secondaryActionText: '',
            mainActionText: t('error-panel.main-button'),
          });
        } catch (e) {}
      }
    },
  };
};

export type InstallProgressHandler = ReturnType<typeof createInstallProgressHandler>;
